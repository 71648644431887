<template>
  <div class="auth-page__holder">
    <header class="auth-page__header">
      <div class="container">
        <div class="flex flex--between flex--v-center">
          <router-link class="main-nav__logo-holder-mobile"
                       area-label="logo"
                       to="/"><logo color="dark"/></router-link>
        </div>
      </div>
    </header>
    <div class="auth-page__logo">
      <img class="img-fluid"
           alt="Rosenthal Consulting Logo"
           :src="require('@/assets/custom/rosenthal-consulting-logo.png')">
    </div>
    <div class="auth-page__form">
      <h1 class="auth-page__title">{{ title }}</h1>
      <br>
      <form action="#"
            novalidate
            @submit.prevent="setPassword">
        <div class="auth-page__row"
             :class="{ 'has-error': $v.form.password.$error }">

          <label class="input__holder">
            <input name="password"
                   type="password"
                   class="input"
                   v-model="form.password"
                   id="password"/>
            <span class="input__label"
                  :class="{ 'input__label--active': !!form.password }">{{ $t('common.password') }}</span>
          </label>
          <password-complexity :password="form.password"
                               @score="scoreChanged"/>
        </div>
        <div class="auth-page__row"
             :class="{ 'has-error': $v.form.confirmPassword.$error }">
          <label class="input__holder">
            <input name="confirm-password"
                   type="password"
                   class="input"
                   v-model="form.confirmPassword"
                   id="confirm-password"/>
            <span class="input__label"
                  :class="{ 'input__label--active': !!form.confirmPassword }">{{ $t('common.confirm_password') }}</span>
          </label>
        </div>
        <div class="flex flex--v-center flex--between">
          <button class="btn btn--primary btn--block btn--uppercase"
                  :class="{ 'btn--pending': passwordUpdatedPending }"
                  type="submit"
                  :disabled="$v.form.$error || passwordUpdatedPending">
            <icon name="sync"
                  class="btn__pending-icom"
                  color="white"
                  :rotate="passwordUpdatedPending"></icon>
            {{ $t('common.save') }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
  import { MINIMUM_PASSWORD_LENGTH } from '@/constants';
  import { required, minLength, sameAs } from "vuelidate/lib/validators";
  import Icon from '@/components/icon/Icon.vue';
  import Logo from '@/components/logo/Logo.vue';
  import PasswordComplexity from '@/components/password-complexity/PasswordComplexity.vue';

  export default {
    name: 'recovery-password',
    components: {
      Logo,
      Icon,
      PasswordComplexity,
    },
    data() {
      return {
        form: {
          password: '',
          confirmPassword: '',
        },
        title: this.$t('page.recovery_password_title'),
        passwordUpdatedPending: false,
        passwordScore: null,
      };
    },
    metaInfo() {
      return {
        title: this.title,
      }
    },
    validations: {
      form: {
        password: {
          required,
          minLength: minLength(MINIMUM_PASSWORD_LENGTH),
        },
        confirmPassword: {
          required,
          minLength: minLength(MINIMUM_PASSWORD_LENGTH),
          sameAs: sameAs('password'),
        },
      }
    },
    methods: {
      async setPassword() {
        this.$v.form.$touch();

        if (this.$v.form.$error || this.passwordUpdatedPending) return;
        this.$v.form.$reset();
        this.passwordUpdatedPending = true;

        if (this.passwordScore < 2) {
          this.passwordUpdatedPending = false;
          return this.$toasted.show(this.$t('change_password.password_too_weak'), { type: 'error' });
        }

        const { success } = await this.$store.dispatch('RESET_PASSWORD', this.form.password);
        this.passwordUpdatedPending = false;

        if (success) {
          this.$router.push('/login');
        } else {
          this.$toasted.show('Something went wrong', { type: 'error' });
        }
      },
      scoreChanged(score) {
        this.passwordScore = score;
      },
    }
  }
</script>

<style lang="scss">
  @import "@/styles/variables.scss";

  .change-password {
    &__complexity-holder {
      font-size: 0.875rem;
      line-height: 1.125rem;
    }

    &__complexity {
      font-weight: 600;

      &--red {
        color: $red;
      }

      &--orange {
        color: $orange;
      }

      &--green {
        color: $green;
      }
    }
  }
</style>
