import Vue from 'vue';
import Router from 'vue-router';
import Login from './views/Login.vue';
import LoginAdmin from './views/LoginAdmin.vue';
import Register from './views/Register.vue';
import ResetPassword from './views/ResetPassword.vue';
import ResetPasswordAdmin from './views/ResetPasswordAdmin.vue';
import RecoveryPassword from './views/RecoveryPassword.vue';
import RecoveryPasswordAdmin from './views/RecoveryPasswordAdmin.vue';
import ChangePassword from './views/ChangePassword.vue';
import OrderDataProcessing from './views/OrderDataProcessing.vue';
import Home from './views/Home.vue';
import NotFound from './views/NotFound.vue';
import store from './store';
import i18n from './i18n';

Vue.use(Router);

const isAdminPanel = window.location.pathname.indexOf('/admin') > -1;

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      meta: {
        requiresAuth: true,
      },
      component: Home
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: { hideNavigation: true },
    },
    {
      path: '/admin/login',
      name: 'admin-login',
      component: LoginAdmin,
      meta: { hideNavigation: true },
    },
    {
      path: '/login/:token',
      name: 'change-password',
      component: ChangePassword,
      meta: { hideNavigation: true },
      beforeEnter: async (to, from, next) => {
        await store.commit('SET_SESSION_DATA', {
          ...store.state.session,
          token: to.params.token,
        });

        next();
      },
    },
    {
      path: '/admin/reset-password',
      name: 'admin-reset-password',
      component: ResetPasswordAdmin,
      meta: { hideNavigation: true },
    },
    {
      path: '/reset-password',
      name: 'reset-password',
      component: ResetPassword,
      meta: { hideNavigation: true },
    },
    {
      path: '/recovery-password/:token',
      name: 'recovery-password',
      component: RecoveryPassword,
      meta: { hideNavigation: true },
      beforeEnter: async (to, from, next) => {
        await store.commit('SET_SESSION_DATA', {
          ...store.state.session,
          token: to.params.token,
        });

        next();
      },
    },
    {
      path: '/admin/recovery-password/:token',
      name: 'admin-recovery-password',
      component: RecoveryPasswordAdmin,
      meta: { hideNavigation: true },
      beforeEnter: async (to, from, next) => {
        await store.commit('SET_SESSION_DATA', {
          ...store.state.session,
          token: to.params.token,
        });

        next();
      },
    },
    {
      path: '/register',
      name: 'register',
      component: Register,
      meta: { hideNavigation: true },
    },
    {
      path: '/order-data-processing',
      name: 'order-data-processing',
      component: OrderDataProcessing,
      meta: {
        hideNavigation: true
      }
    },
    {
      path: '/users',
      name: 'users',
      meta: {
        requiresAuth: true,
        roles: ['employer', 'manager'],
      },
      // route level code-splitting
      // this generates a separate chunk (users.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: 'users' */ './views/Users.vue'),
      beforeEnter: async (to, from, next) => {
        await store.dispatch('GET_USERS');
        await store.dispatch('ADD_EMPLOYER');
        // store.dispatch('GET_COURSES'),
        store.dispatch('GET_COMPANY_PROGRESS'),
        next();
      },
    },
    {
      path: '/admin/policyholders',
      name: 'policyholders',
      meta: {
        requiresAuth: true,
        roles: ['admin'],
      },
      component: () =>
        import(/* webpackChunkName: 'policyholders' */ './views/Policyholders.vue'),
      beforeEnter: async (to, from, next) => {
        await store.dispatch('GET_EMPLOYERS');
        next();
      },
    },
    { path: '/admin', redirect: '/admin/policyholders' },
    {
      path: '/admin/admins',
      name: 'admins',
      meta: {
        requiresAuth: true,
        roles: ['admin'],
      },
      component: () =>
        import(/* webpackChunkName: 'admins' */ './views/Admins.vue'),
      beforeEnter: async (to, from, next) => {
        await store.dispatch('GET_ADMINS');
        next();
      },
    },
    {
      path: '/admin/upload',
      name: 'admins-upload',
      meta: {
        requiresAuth: true,
        roles: ['admin'],
      },
      component: () =>
        import(/* webpackChunkName: 'admins' */ './views/AdminsUpload.vue'),
      beforeEnter: async (to, from, next) => {
        await store.dispatch('GET_EMPLOYERS');
        next();
      },
    },
    {
      path: '/admin/policyholders/:id',
      name: 'policyholder-details',
      meta: {
        requiresAuth: true,
        roles: ['admin'],
      },
      component: () =>
        import(/* webpackChunkName: 'policyholder-details' */ './views/PolicyholderDetails.vue'),
      beforeEnter: async (to, from, next) => {
        await store.dispatch('GET_USER_DETAILS', to.params.id);
        next();
      },
    },
    {
      path: '/course-settings',
      name: 'course-settings',
      meta: {
        requiresAuth: true,
        roles: ['employer', 'manager'],
      },
      component: () => import(/* webpackChunkName: 'course-settings' */ './views/CourseSettings.vue'),
      beforeEnter: async (to, from, next) => {
        await Promise.all([
          store.dispatch('GET_USERS'),
          store.dispatch('GET_COURSES'),
          store.dispatch('GET_COMPANY_PROGRESS'),
        ]);
        next();
      },
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      meta: {
        requiresAuth: true,
        roles: ['employer', 'manager'],
      },
      component: () => import(/* webpackChunkName: 'dashboard' */ './views/Dashboard.vue'),
      beforeEnter: async (to, from, next) => {
        await Promise.all([
          store.dispatch('GET_USERS'),
          store.dispatch('GET_COURSES'),
          store.dispatch('GET_COMPANY_PROGRESS'),
          store.dispatch('GET_COMPANY_STATISTIC'),
          
        ]);
        await store.dispatch('ADD_EMPLOYER')
        next();
      },
    },
    {
      path: '/proofOfInsurance',
      name: 'proofOfInsurance',
      meta: {
        requiresAuth: true,
        roles: ['employer', 'manager'],
      },
      component: () => import(/* webpackChunkName: 'proofOfInsurance' */ './views/ProofOfInsurance.vue'),
      beforeEnter: async (to, from, next) => {
        await Promise.all([
          store.dispatch('GET_USERS'),
          store.dispatch('GET_COURSES'),
          store.dispatch('GET_COMPANY_PROGRESS'),
          store.dispatch('GET_COMPANY_STATISTIC'),
        ]);
        next();
      },
    },
    {
      path: '/personalCertificate',
      name: 'personalCertificate',
      meta: {
        requiresAuth: true,
        roles: ['employee', 'employer', 'manager'],
      },
      component: () => import(/* webpackChunkName: 'proofOfInsurance' */ './views/PersonalCertificate.vue'),
      beforeEnter: async (to, from, next) => {
        await Promise.all([
          store.dispatch('GET_USERS'),
          store.dispatch('GET_COURSES'),
          store.dispatch('GET_COURSE_PROGRESSES')
        ]);
        next();
      },
    },
    {
      path: '/trainings',
      name: 'trainings',
      meta: {
        requiresAuth: true,
      },
      component: () => import(/* webpackChunkName: 'my-trainings' */ './views/MyTrainings.vue'),
      beforeEnter: async (to, from, next) => {
        await Promise.all([
          store.dispatch('GET_COURSES'),
          store.dispatch('GET_COURSE_PROGRESSES'),
        ]);
        next();
      },
    },
    {
      path: '/trainings/:slug',
      name: 'training',
      meta: {
        requiresAuth: true,
      },
      component: () => import(/* webpackChunkName: 'my-training' */ './views/MyTraining.vue'),
      beforeEnter: async (to, from, next) => {
        await Promise.all([
          store.dispatch('GET_COURSE_DATA', to.params.slug),
          store.dispatch('GET_COURSE_PROGRESS', to.params.slug),
        ]);
        next();
      },
      children: [
        {
          path: 'quiz/:step',
          name: 'training-quiz',
          meta: {
            quiz: true,
          },
          component: () => import(/* webpackChunkName: 'my-training-quiz' */ './views/MyTrainingQuiz.vue'),
        },
        {
          path: ':slide?/:step?',
          name: 'training-module',
          meta: {
            module: true,
          },
          component: () => import(/* webpackChunkName: 'my-training-module' */ './views/MyTrainingModule.vue'),
        },
      ],
    },
    {
      path: '/account-settings',
      name: 'account-settings',
      meta: {
        requiresAuth: true,
      },
      component: () => import(/* webpackChunkName: 'account-settings' */ './views/AccountSettings.vue'),
      beforeEnter: async (to, from, next) => {
        await await store.dispatch('INIT_USER_DATA', true);
        next();
      },
    },
    {
      path: '/impressum',
      name: 'impressum',
      meta: {
        requiresAuth: true,
      },
      component: () => import(/* webpackChunkName: 'impressum' */ './views/Impressum.vue'),
    },
    {
      path: '/datenschutzhinweise',
      name: 'datenschutzhinweise',
      meta: {
        requiresAuth: false,
        hideNavigation: true
      },
      component: () => import(/* webpackChunkName: 'datenschutzhinweise' */ './views/Datenschutzhinweise.vue'),
    },
    {
      path: '/data-protection-notes',
      name: 'data-protection-notes',
      meta: {
        requiresAuth: false,
        hideNavigation: true
      },
      component: () => import(/* webpackChunkName: 'data-protection-notes' */ './views/DataProtectionNotes.vue')
    },
    {
      path: '**',
      name: 'not-found',
      meta: {
        hideNavigation: true,
      },
      component: NotFound,
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  if (isAdminPanel) {
    store.dispatch('CHANGE_LANGUAGE', 'de');
  }

  if (to.path === '/logout' && store.getters.logged) {
    const logoutType = store.getters.isAdmin ? 'LOG_OUT_ADMIN' : 'LOG_OUT';

    const { success, message = 'Something went wrong' } = await store.dispatch(logoutType);


    if (success) {
      Vue.toasted.show(i18n.t('logout.logout_success'), { type: 'success' });
    } else {
      Vue.toasted.show(message, { type: 'error' });
    }

    if (logoutType === 'LOG_OUT_ADMIN') {
      return next('/admin/login');
    } else {
      return next('/login');
    }
  }

  if (to.path.indexOf('/recovery-password') > -1) {
    return next();
  }

  if (store.getters.logged && !store.getters.isAdmin) {
    await store.dispatch('INIT_USER_DATA');
  }

  if (store.getters.isEmployer && !store.getters.isSettingsDataFullFilled) {
    if (to.path.indexOf('/account-settings') > -1) {
      return next();
    } else if (from.path.indexOf('/account-settings') === -1) {
      next('/account-settings');
    }

    Vue.toasted.show(i18n.t('account_settings.fill_required_fields') ,{
      type: 'warning',
      duration: 10000,
    });

    return;
  }

  if (to.path === '/' && store.getters.isAdmin) return next ('/admin/policyholders');
  if (to.path === '/' && (store.getters.isEmployer || store.getters.isManager)) return next ('/course-settings');
  if (to.path === '/' && store.getters.isEmployee) return next ('/trainings');

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.logged) {
      return next({
        path: isAdminPanel ? '/admin/login' : '/login',
        query: { next: to.fullPath },
      });
    } else {
      if (to.matched.some(record => record.meta.roles && record.meta.roles.indexOf(store.getters.role) === -1)) {
        return next('/');
      }
    }
  }

  store.commit('SET_ROUTE_FROM', from);
  store.dispatch('SET_NOTIFICATIONS')

  next();
});

export default router;
