<template>
  <transition name="modal">
    <div class="modal-mask"
         @mousedown="closeModal()"
         :class="[modalClass, htmlClass]"
         :style="overlayRightPosition">
      <div class="modal-wrapper">
        <div
          class="modal-container"
          :class="{'modal-container--lg': containerSize === 'lg'}"
          @mousedown="$event.stopPropagation()">
          <div class="modal-header">
            <slot name="header">
              default header
            </slot>

            <span v-if="closable" class="modal-cancel"
                  @click="closeModal()">
              <icon name="cancel" :width="iconWidth" :color="iconColor"></icon>
            </span>
          </div>

          <div class="modal-body">
            <slot name="body">
              default body
            </slot>
          </div>

          <div class="modal-footer">
            <slot name="footer">
              default footer
              <button class="modal-default-button"
                      type="button"
                      @click="closeModal()">
                OK
              </button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  import Icon from '../icon/Icon.vue'

  const $html = document.querySelector('html');
  const $body = document.querySelector('body');

  const getScrollBarWidth = () => {
    const scrollDiv = document.createElement("div");
    scrollDiv.className = "scrollbar-measure";
    $body.appendChild(scrollDiv);

    const scrollBarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
    $body.removeChild(scrollDiv);

    return scrollBarWidth;
  };

  const getBodyScrollBarWidth = () => window.innerWidth - $body.clientWidth;

  export default {
    name: 'modal',
    components: {
      Icon
    },
    data () {
      return {
        modalClass: this.modalStyle ? 'modal-mask--' + this.modalStyle : null,
        scrollBarWidth : getScrollBarWidth(),
        scroll: 0
      }
    },
    computed: {
      iconColor() {
        if(this.modalStyle === 'mobile'){
          return 'white'
        } else {
          return 'default'
        }
      },
      overlayRightPosition() {
        return {
          right: '-' + (this.scrollBarWidth + 1) + 'px'
        };
      }
    },
    props: {
      modalStyle: String,
      iconWidth: {
        type: Number,
        default: 22
      },
      containerSize: String,
      closable: {
        type: Boolean,
        default() {return true}
      },
      htmlClass: {
        type: String
      }
    },
    methods: {
      closeModal() {
        this.$emit('close');
      },
      scrollTo() {
        $body.style.position = 'static';
        $body.style.top = 0;
        $body.style.overflow = 'visible';

        if (!this.scroll) return;

        $html.scrollTop = this.scroll;
        $body.scrollTop = this.scroll;

        this.scroll = 0;
      }
    },
    mounted() {
      $body.style.paddingRight = getBodyScrollBarWidth() + 'px';

      this.scroll = $html.scrollTop || $body.scrollTop || this.scroll;
      $body.style.position = 'fixed';
      $body.style.top = '-' + this.scroll + 'px';
      $body.style.overflow = 'hidden';
      $body.classList.add('modal-open');
    },
    destroyed() {
      $body.removeAttribute('style');
      $body.classList.remove('modal-open');
      this.scrollTo();
    }
  }
</script>

<style lang="scss">
  .scrollbar-measure {
    width: 100px;
    height: 100px;
    overflow: scroll;
    position: absolute;
    top: -9999px;
  }

  .modal-open {
    overflow: hidden;
    width: 100%;
    padding-right: 17px;
  }
</style>

<style lang="scss">
  @import "@/styles/variables.scss";

  .modal-mask {
    position: fixed;
    z-index: 1220;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    transition: opacity .3s ease;

    .modal-wrapper {
      width: 100%;
      min-height: 100%;
      display: flex;
      padding: 15px;
    }
  }

  .modal-container {
    position: relative;
    max-width: 356px;
    width: 100%;
    border: 1px solid $table-border-color;
    box-sizing: border-box;
    margin: auto;
    padding: 22px 25px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    transition: all .3s ease;
    font-family: Helvetica, Arial, sans-serif;

    &--lg {
      max-width: 980px;
    }
  }

  .modal-cancel {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }

  .modal-header {
    padding: 0;

    h3 {
      margin: 0 0 10px;
      font-size: 1.6rem;
      line-height: 2.2rem;
      color: $dark-blue;
    }
  }

  .modal-body {
    margin: 20px 0;
    padding: 0;
  }

  .modal-footer {
    padding: 0;
  }

  .modal-default-button {
    float: right;
  }

  /*
   * The following styles are auto-applied to elements with
   * transition="modal" when their visibility is toggled
   * by Vue.js.
   *
   * You can easily play with the modal transition by editing
   * these styles.
   */

  .modal-enter {
    opacity: 0;
  }

  .modal-leave-active {
    opacity: 0;
  }

  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
</style>
