<template>
    <div>
        <modal v-if="showModal"
               class="video-modal"
               @close="closeModal">
            <div slot="header">
                <h3>{{ $t('account_settings.first_steps') }}</h3>
            </div>
            <div slot="body">
                <video-player ref="videoPlayer"
                              :options="playerOptions"
                              :playsinline="true">
                </video-player>
            </div>
            <div slot="footer">
                <button @click="closeModal"
                        type="button"
                        class="btn btn--default btn--sm">
                    {{ $t('common.close') }}
                </button>
            </div>
        </modal>
    </div>
</template>

<script>
  import 'video.js/dist/video-js.css';
  import {videoPlayer} from 'vue-video-player'

  import Modal from '@/components/modal/Modal.vue';
  import eventBus from '@/components/EventBus.js';

  export default {
    name: 'video-modal',
    components: {
      Modal,
      videoPlayer
    },
    mounted() {
      eventBus.$on('showVideo', () => {
        this.showModal = true;
      });
    },
    destroyed() {
      eventBus.$off('showVideo');
    },
    data() {
      return {
        showModal: false,
        playerOptions: {
          height: '360',
          autoplay: false,
          muted: false,
          responsive: true,
          language: 'de',
          playbackRates: [0.7, 1.0, 1.5, 2.0],
          sources: [{
            type: 'video/mp4',
            src: 'https://de-cyberdirekt-uploads-public.s3.eu-central-1.amazonaws.com/general/videos/e-learning_tutorial_rosenthal.mp4'
          }],
          poster: require('@/assets/custom/video-tutorial-poster.png')
        }
      }
    },
    methods: {
      closeModal() {
        this.showModal = false;
        this.$store.dispatch('SET_VIDEO_SHOWN');
      }
    }
  }
</script>

<style lang="scss">
    @import "@/styles/variables.scss";

    .video-modal {
        .modal-container {
            max-width: 700px;
            padding: 0;
        }

        .modal-header {
            padding: 30px 30px 16px;
            background-color: lighten($secondary, 50%);
            border-bottom: 1px solid $table-border-color;
        }

        .modal-body {
            margin: 0;
            padding: 27px 29px 35px;
        }

        .modal-footer {
            padding: 0 30px 30px 30px;
        }

        h5 {
            font-size: 0.975rem;
            line-height: 1.25rem;
            color: $dark-blue;
            margin: 0 0 14px;
        }
    }
</style>
