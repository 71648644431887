import './helpers/polyfills';
import './styles/main.scss';
import Vue from 'vue';
import VueMeta from 'vue-meta';
import VueTouch from 'vue-touch';
import Vuelidate from 'vuelidate';
import Toasted from 'vue-toasted';
import VTooltip from 'v-tooltip';
import Tabs from 'vue-tabs-component';
import moment from 'moment';
import VueMoment from 'vue-moment';
import VueMatchHeights from 'vue-match-heights';
import AsyncComputed from 'vue-async-computed';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';
import ClickOutside from './directives/ClickOutside';
import interceptorsSetup from './helpers/interceptors';
import autofocus from 'vue-autofocus-directive';
import feather from 'vue-icon'

import 'core-js/es/array';
import 'core-js/es/object';

Vue.use(feather, 'v-icon')
Vue.use(VueMeta);
Vue.use(VueTouch);
Vue.use(Vuelidate);
Vue.use(Toasted, {
  duration: 3000,
  position: 'top-right',
  theme: 'bubble',
  singleton: 'true',
});
Vue.use(VTooltip);
Vue.use(Tabs);
Vue.use(VueMoment, { moment });
Vue.use(VueMatchHeights);
Vue.use(AsyncComputed);
Vue.directive('autofocus', autofocus);

interceptorsSetup();

Vue.directive('click-outside', ClickOutside);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app');
