<template>
    <div class="auth-page__holder">
        <header class="auth-page__header">
            <div class="container">
                <div class="flex flex--between flex--v-center">
                    <router-link class="main-nav__logo-holder-mobile"
                                 area-label="logo"
                                 to="/">
                        <!-- <logo color="dark"/> -->
                    </router-link>

                    <div>
                        <span class="mobile-hide">{{ $t('register.already_have_an_account') }} </span>
                        <router-link to="/login"
                                     class="link">{{ $t('register.sign_in') }}
                        </router-link>
                    </div>
                </div>
            </div>
        </header>
        <div class="auth-page__logo">
            <img class="img-fluid"
                 alt="Rosenthal Consulting Logo"
                 :src="require('@/assets/custom/rosenthal-consulting-logo.png')">
        </div>
        <div class="container">
            <div class="row">
                <div class="col-xs-12 col-md-6">
                    <div class="auth-page__form">
                        <h1 class="auth-page__title">{{ title }}</h1>
                        <p class="auth-page__sub-title">{{
                            $t('register.for_registration_enter_your_details_below')}}</p>
                        <form action="#"
                              novalidate
                              @submit.prevent="register">
                            <div class="auth-page__row" :class="{ 'has-error': $v.form.firstname.$error }">
                                <label class="input__holder">
                                    <input type="text" v-model="form.firstname" name="firstname" class="input"/>
                                    <span
                                            class="input__label"
                                            :class="{ 'input__label--active': !!form.firstname }"
                                    >{{ $t('common.first_name') }}</span>
                                </label>
                            </div>

                            <div class="auth-page__row" :class="{ 'has-error': $v.form.lastname.$error }">
                                <label class="input__holder">
                                    <input type="text" v-model="form.lastname" name="lastname" class="input"/>
                                    <span
                                            class="input__label"
                                            :class="{ 'input__label--active': !!form.lastname }"
                                    >{{ $t('common.last_name') }}</span>
                                </label>
                            </div>

                            <div class="auth-page__row" :class="{ 'has-error': $v.form.companyName.$error }">
                                <label class="input__holder">
                                    <input type="text" v-model="form.companyName" name="companyName" class="input"/>
                                    <span
                                            class="input__label"
                                            :class="{ 'input__label--active': !!form.companyName }"
                                    >{{ $t('common.company_name') }}</span>
                                </label>
                            </div>

                            <div class="auth-page__row" :class="{ 'has-error': $v.form.email.$error }">
                                <label class="input__holder">
                                    <input type="email" v-model="form.email" name="email" class="input"/>
                                    <span
                                            class="input__label"
                                            :class="{ 'input__label--active': !!form.email }"
                                    >{{ $t('common.email') }}</span>
                                </label>
                            </div>

                            <div class="auth-page__row" :class="{ 'has-error': $v.form.password.$error }">
                                <label class="input__holder">
                                    <input type="password" v-model="form.password" name="password" class="input"/>
                                    <span
                                            class="input__label"
                                            :class="{ 'input__label--active': !!form.password }"
                                    >{{ $t('common.password') }}</span>
                                </label>
                                <password-complexity :password="form.password"
                                                     @score="scoreChanged"/>
                            </div>

                            <div class="auth-page__row" :class="{ 'has-error': $v.form.confirmPassword.$error }">
                                <label class="input__holder">
                                    <input
                                            name="confirm-password"
                                            type="password"
                                            class="input"
                                            v-model="form.confirmPassword"
                                            id="confirm-password"
                                    />
                                    <span
                                            class="input__label"
                                            :class="{ 'input__label--active': !!form.confirmPassword }"
                                    >{{ $t('common.confirm_password') }}</span>
                                </label>
                            </div>

                          <div class="auth-page__row">
                            <p>
                              <b>Wichtig!</b> Bevor Sie sich registrieren, stellen Sie sicher, dass Sie dafür berechtigt sind.
                            </p>
                            <p>
                              Wenn Sie MitarbeiterIn sind, warten Sie bitte, bis die zuständige Person aus Ihrem Unternehmen Sie zum Training einlädt. Sie erhalten einen Einladungslink per E-Mail.
                            </p>
                          </div>

                            <div class="auth-page__row" :class="{ 'has-error': $v.form.confirmLicense.$error }">
                                <div class="checkbox">
                                    <input name="confirm-licence"
                                           type="checkbox"
                                           class="checkbox__input"
                                           v-model="form.confirmLicense"
                                           id="confirm-license"/>
                                    <label for="confirm-license"
                                           class="checkbox__label">
                                        {{ $t('common.confirm_to_be_customer_of', { name: 'Allianz' }) }}
                                    </label>
                                </div>
                            </div>

                            <div class="auth-page__row" :class="{ 'has-error': $v.form.dataProtectionPolicyAccepted.$error }">
                                <div class="checkbox">
                                    <input name="accept_data-policy"
                                           type="checkbox"
                                           class="checkbox__input"
                                           v-model="form.dataProtectionPolicyAccepted"
                                           id="accept_data-policy"/>
                                    <label for="accept_data-policy"
                                           class="checkbox__label"
                                           v-html="$t('common.privacy_policy_checkbox')">
                                    </label>
                                </div>
                            </div>

                            <div :class="{ 'has-error': $v.form.orderProcessingAccepted.$error }">
                                <div class="checkbox">
                                    <input name="accept_order-processing"
                                           type="checkbox"
                                           class="checkbox__input"
                                           v-model="form.orderProcessingAccepted"
                                           id="accept_order-processing"/>
                                    <label for="accept_order-processing"
                                           class="checkbox__label"
                                           v-html="$t('common.order_processing_checkbox')">
                                    </label>
                                </div>
                            </div>

                            <div class="auth-page__buttons-holder">
                                <button class="btn btn--primary btn--lg btn--block btn--uppercase"
                                        :class="{ 'btn--pending': logInPending }"
                                        type="submit"
                                        :disabled="$v.form.$error || logInPending">
                                    <icon name="sync"
                                          class="btn__pending-icom"
                                          color="white"
                                          :rotate="logInPending"></icon>
                                    {{ $t('common.register') }}
                                </button>
                            </div>
                            <div class="text--center">
                                {{ $t('register.already_have_an_account') }}
                                <router-link to="/login"
                                             class="link">{{ $t('register.sign_in') }}
                                </router-link>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="col-xs-12 col-md-6" ref="videoWrapper">
                    <video-player class="video"
                                  ref="videoPlayer"
                                  :options="playerOptions"
                                  :playsinline="true">
                    </video-player>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import {required, email, minLength, sameAs} from "vuelidate/lib/validators";
  import {videoPlayer} from 'vue-video-player'
  import Logo from '@/components/logo/Logo.vue';
  import Icon from '@/components/icon/Icon.vue';
  import {COMPANY_NAME, MINIMUM_PASSWORD_LENGTH} from '@/constants';
  import PasswordComplexity from '@/components/password-complexity/PasswordComplexity.vue';
  import {throttle} from 'lodash';
  import { loadLanguageAsync } from '@/i18n';
  import { mapGetters } from 'vuex';



  export default {
    name: 'register',
    components: {
      Logo,
      Icon,
      PasswordComplexity,
      videoPlayer
    },
    mounted() {
      // force language to be deutsch on registration view
      if (!this.user.lang) {
        loadLanguageAsync('de');
      }

      window.addEventListener('resize', this.onResize);
      this.onResize();
    },
    destroyed() {
      window.removeEventListener('resize', this.onResize);
    },
    data() {
      return {
        form: {
          firstname: '',
          lastname: '',
          companyName: '',
          email: '',
          password: '',
          confirmPassword: '',
          confirmLicense: false,
          dataProtectionPolicyAccepted: false,
          orderProcessingAccepted: false
        },
        title: this.$t('page.register_title', {name: COMPANY_NAME}),
        logInPending: false,
        passwordScore: null,
        playerOptions: {
          width: '360',
          responsive: true,
          autoplay: false,
          muted: false,
          language: 'de',
          playbackRates: [0.7, 1.0, 1.5, 2.0],
          sources: [{
            type: 'video/mp4',
            src: 'https://de-cyberdirekt-uploads-public.s3.eu-central-1.amazonaws.com/general/videos/e-learning_tutorial_rosenthal.mp4'
          }],
          poster: require('@/assets/custom/video-tutorial-poster.png')
        }
      };
    },
    computed: {
      ...mapGetters([
        'user',
      ]),
    },
    metaInfo() {
      return {
        title: this.title
      }
    },
    validations: {
      form: {
        firstname: {required},
        lastname: {required},
        companyName: {required},
        email: {
          required,
          email
        },
        password: {
          required,
          minLength: minLength(MINIMUM_PASSWORD_LENGTH)
        },
        confirmPassword: {
          required,
          minLength: minLength(MINIMUM_PASSWORD_LENGTH),
          sameAsPassword: sameAs('password')
        },
        confirmLicense: {
          isChecked(value) {
            return value === true
          }
        },
        dataProtectionPolicyAccepted: {
          isChecked(value) {
            return value === true
          }
        },
        orderProcessingAccepted: {
          isChecked(value) {
            return value === true
          }
        }
      }
    },
    methods: {
      onResize: throttle(function() {
        this.playerOptions.width = '' + (this.$refs.videoWrapper.clientWidth - 20);
      }, 100),
      async register() {
        this.$v.$touch();

        if (this.$v.$error || this.logInPending) return;

        this.$v.$reset();
        this.logInPending = true;

        if (this.passwordScore < 2) {
          this.logInPending = false;
          return this.$toasted.show(this.$t('change_password.password_too_weak'), {type: 'error'});
        }

        const {success, message = 'Something went wrong'} = await this.$store.dispatch('REGISTER', this.form);
        this.logInPending = false;

        if (success) {
          const {success, message} = await this.$store.dispatch('LOG_IN', this.form);

          if (success) {
            this.$router.push('/');
          } else {
            this.$toasted.show(message, {type: 'error'});
          }
        } else {
          this.$toasted.show(message, {type: 'error'});
        }
      },
      scoreChanged(score) {
        this.passwordScore = score;
      }
    }
  }
</script>

<style lang="scss" scoped>
    .auth-page__form {
        margin-bottom: 5rem;
    }

    .video {
        margin: 0 auto 3rem 0;
    }
</style>
