<template>
  <div class="page__holder">
    <h1>{{ title }}</h1>
  </div>
</template>

<script>
export default {
  name: 'home',
  data() {
    return {
      title: this.$t('page.home_title'),
    }
  },
  metaInfo() {
    return {
      title: this.title,
    }
  },
};
</script>
