export function isConfirmedToDelete(value) {
  if (!value) return true;

  return value === 'DELETE';
}

export function isConfirmedToReset(value) {
  if (!value) return true;

  return value === 'RESET';
}

export function isInsuranceNumberValid(value) {
  if (!value) return true;

  return /^ROSENTHAL$/.test(value);
}
